import { render, staticRenderFns } from "./CarouselItem.vue?vue&type=template&id=43e89e71&scoped=true&"
import script from "./CarouselItem.vue?vue&type=script&lang=js&"
export * from "./CarouselItem.vue?vue&type=script&lang=js&"
import style0 from "./CarouselItem.vue?vue&type=style&index=0&id=43e89e71&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43e89e71",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('43e89e71')) {
      api.createRecord('43e89e71', component.options)
    } else {
      api.reload('43e89e71', component.options)
    }
    module.hot.accept("./CarouselItem.vue?vue&type=template&id=43e89e71&scoped=true&", function () {
      api.rerender('43e89e71', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "modules/@ergonode/ui/src/components/Carousel/CarouselItem.vue"
export default component.exports